<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 0C5.82187 0 5.0625 0.759375 5.0625 1.6875V25.3125C5.0625 26.2406 5.82187 27 6.75 27H23.625C24.5531 27 25.3125 26.2406 25.3125 25.3125V6.75L18.5625 0H6.75Z" fill="#E2E5E7"/>
    <path d="M20.25 6.75H25.3125L18.5625 0V5.0625C18.5625 5.99062 19.3219 6.75 20.25 6.75Z" fill="#B0B7BD"/>
    <path d="M25.3125 11.8125L20.25 6.75H25.3125V11.8125Z" fill="#CAD1D8"/>
    <path d="M21.9375 21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812H2.53125C2.06719 22.7812 1.6875 22.4016 1.6875 21.9375V13.5C1.6875 13.0359 2.06719 12.6562 2.53125 12.6562H21.0938C21.5578 12.6562 21.9375 13.0359 21.9375 13.5V21.9375Z" fill="#50BEE8"/>
    <path d="M4.88189 20.2503C4.65914 20.2503 4.41614 20.1279 4.41614 19.8318V16.0003C4.41614 15.7573 4.65914 15.5818 4.88189 15.5818H6.42679C9.50986 15.5818 9.44151 20.2503 6.4867 20.2503H4.88189ZM5.30714 16.4044V19.4268H6.42679C8.24845 19.4268 8.32861 16.4044 6.42679 16.4044H5.30714Z" fill="white"/>
    <path d="M12.0233 20.3176C10.7754 20.3716 9.47937 19.5413 9.47937 17.8884C9.47937 16.2287 10.7745 15.4128 12.0233 15.4128C13.2037 15.4727 14.4381 16.2895 14.4381 17.8884C14.4381 19.4882 13.2037 20.3176 12.0233 20.3176ZM11.949 16.2903C11.193 16.2903 10.3704 16.8236 10.3704 17.8892C10.3704 18.949 11.1939 19.489 11.949 19.489C12.7253 19.489 13.5547 18.949 13.5547 17.8892C13.5547 16.8227 12.7253 16.2903 11.949 16.2903Z" fill="white"/>
    <path d="M15.2323 17.8818C15.2323 16.5799 16.0491 15.4602 17.6007 15.4602C18.188 15.4602 18.6537 15.6357 19.1456 16.061C19.3279 16.2289 19.3481 16.5259 19.1659 16.7149C18.9836 16.876 18.7069 16.8566 18.5449 16.6946C18.269 16.4044 17.9855 16.3242 17.6007 16.3242C16.5621 16.3242 16.0634 17.06 16.0634 17.8826C16.0634 18.7196 16.5553 19.4883 17.6007 19.4883C17.9855 19.4883 18.3432 19.3322 18.6537 19.0563C18.8621 18.8943 19.1524 18.9744 19.2739 19.1373C19.3819 19.2858 19.435 19.5355 19.1996 19.7709C18.7271 20.2105 18.161 20.2983 17.5999 20.2983C15.9681 20.2983 15.2323 19.1837 15.2323 17.8818Z" fill="white"/>
    <path d="M21.0938 22.7812H5.0625V23.625H21.0938C21.5578 23.625 21.9375 23.2453 21.9375 22.7812V21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812Z" fill="#CAD1D8"/>
  </svg>
</template>